import React ,{useState} from "react";
import { useTranslation } from "react-i18next";
import { Link,useNavigate } from "react-router-dom";
import logo from "../assets/images/logo-b.webp";
import btm18 from "../assets/images/btm-18.svg";
const FooterSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
   
       <div class="pay">
       <div class="language-sec border-top">
       <h6>
          <Link to="/info/tnc">{t("Terms_Condition")}</Link>
        </h6>
        <h6>
          <Link to="/info/about-us">{t("About_Us")}</Link>
        </h6>
        <h6>
          <Link to="/info/faq">{t("FAQ")}</Link>
        </h6>
        <h6>
          <Link to="/info/contact-us">{t("Contact_Us")}</Link>
        </h6>
       
      </div>
      <div class="payment-inner">
        <div class="payment-row">
          <h2>{t("Responsible_Gaming")}</h2>
          <ul>
            <li>
              <Link to="/">
                <img src={btm18} alt="" />
              </Link>
            </li>
           
          </ul>
        </div>
        <div class="payment-row social-row">
          <h2>{t("Certified_By")}</h2>
          <ul>
            <li>
              <Link to="/">
                {" "}
                <img src={logo} alt="" />
              </Link>
            </li>
           
          </ul>
        </div>
      </div>
      <div class="payment-inner">
        
        <div class="payment-row social-row">
          <h2>{t("find_us")}</h2>
          <ul>
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/facebook.svg" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                <img src="../assets/images/instagram.svg" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/twitter.svg" alt="" />
              </Link>
            </li>
            
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/youtube.svg" alt="" />
              </Link>
            </li>
            <li>
              <Link to="/">
                {" "}
                <img src="../assets/images/telegram.svg" alt="" />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div class="border-top footer-bottom">
        <div>
          {/* <h6>{t("Best_Platform")}</h6> */}
          <p>{t("copyright")}</p>
        </div>
      </div>
    </div>
    </>
   
  );
};

export default FooterSection;
