import React, { useContext, useEffect, useRef,useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import bkashLogo from "../assets/images/logo-b.webp";
import rightArrow from "../assets/images/right-arrow.png";
import { Controller, useForm } from "react-hook-form";
import { Form, Button, InputGroup } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import AuthContext from "../context/AuthContext";
import { useTranslation } from "react-i18next";
import { AiFillEye,AiFillEyeInvisible } from "react-icons/ai";
import LayoutNew from "../components/shared/LayoutNew";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const { sendOTP, setBr, lang, setEn,logoutUser ,seconds,minutes,getSendOtpStatus,setSendOtpStatus,sendMobileOTP} = useContext(AuthContext);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    watch,
    formState: { errors },
} = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
        countryCode: 91,
    },
});
  const navigate = useNavigate();
  const onSubmit = async (body) => {
      set_password_same(true);
      if (body.password !== body.confirmPassword) {
        set_password_same(false);
      } else {
      const obj = {
        phone_number: inputRef?.current?.state.selectedCountry?.countryCode+body?.mobile?.substring(inputRef?.current?.state.selectedCountry?.countryCode?.length,
          body?.mobile?.toString()?.length
        ),
          otp:body?.verificationCode,
          newPassword: body?.confirmPassword,
        
      };
      const { status, data } = await apiPost(apiPath.changePassword, obj);
      if (status === 200) {
        if (data.success) {
          logoutUser();
          toast.success(data?.message);
        } else {
          toast.error(data?.message);
        }
      } else {
          toast.error(data?.message);
      }
  }
    };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = (e) => {
      e.preventDefault()
      if (passwordType === "password") {
          setPasswordType("text")
          return;
      }
      setPasswordType("password")
  }

  const [password_same, set_password_same] = useState();

  const newPassword = useRef({});
  newPassword.current = watch("newPassword", "");

  return (
    <>
     <LayoutNew>
      <div className="register-content">
      
       
      <div className="login-form active" >
        <div className="forgot-back-header menu-header">
          <div className="left-arrow">
            <span onClick={() => navigate("/")}>
              <img src={rightArrow} alt="" />
            </span>
          </div>
          <p>{t("Forgot_Password")}</p>
          
        </div>
        <div className="reg-data">
           
        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className="mb-2">
                            <Form.Group className="form-group d-flex  mb-3">
            
            <Controller
            className="form-group d-flex"
            control={control}
            name="mobile"
            rules={{
              required: "Please enter mobile number.",
              validate: (value) => {
                let inputValue = value
                  ?.toString()
                  ?.slice(
                    inputRef?.current?.state?.selectedCountry?.countryCode
                      ?.length,
                    value?.length
                );
                if (inputValue?.length < 10) {
                  return "Mobile number must contain 10 digit";
                } else if (inputValue?.length > 12) {
                  return "Mobile number should not exceed 12 digit";
                } else {
                  return true;
                }
              },
            }}
            render={({ field: { ref, ...field } }) => (
              <>
                <PhoneInput
                  {...field}
                  // isValid={(value, country) => {
                  //   if (value.match(/12345/)) {
                  //     return 'Invalid value: '+value+', '+country.name;
                  //   } else if (value.match(/1234/)) {
                  //     return false;
                  //   } else {
                  //     return true;
                  //   }
                  // }}
                  inputExtraProps={{
                    ref,
                    required: true,
                    autoFocus: true,
                  }}
                  ref={inputRef}
                  inputStyle={{
                    width: "100%",
                    height: "38px",
                  }}
                  country={"bd"}
                  enableSearch
                  countryCodeEditable={false}
                />
              </>
            )}
          />
        </Form.Group>
        {errors?.mobile?.message && (
          <div className="text-danger">{errors?.mobile?.message}</div>
        )}
                            <Form.Group className="login-data-d">
                            <Form.Label>Verification Code</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Enter verification code"
                              maxlength="6"
                              className={errors.verificationCode ? " is-invalid " : ""}
                              {...register("verificationCode", {
                                required: "Please enter verification code",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: 'Please enter a valid number',
                                  
                              },
                              
                              })}
                            />        
                          
                        </Form.Group>

                            {errors?.verificationCode?.message && (<div className="text-danger">{errors?.verificationCode?.message}</div>)}

                            { getSendOtpStatus? 
                      <Button 
                          disabled={seconds > 0 || minutes > 0}
                          
                            onClick={() => sendMobileOTP("resend",inputRef?.current?.state?.formattedNumber.replace(/\s/g, '').replace(/\+/g, ''))} 
                            className="theme-btn py-1 px-3 fs-6">{"Resend Otp"}
                        </Button>:
                        <Button onClick={() => sendMobileOTP("first",inputRef?.current?.state?.formattedNumber.replace(/\s/g, '').replace(/\+/g, ''))} className="theme-btn py-1 px-3 fs-6">{"Send Otp"}
                        </Button>}
                        <div className="countdown-text">
                        {seconds > 0 || minutes > 0 ? (
                          <p>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          <p>Didn't recieve code?</p>
                        )}

                        
                      </div>

                            
                                <Form.Group className=" login-data-d">
                                    <Form.Label> Password</Form.Label>
                                    <div style={{ position: 'relative', width: `100%` }}>
                                        <Form.Control
                                            type={passwordType ? passwordType : "password"}
                                            placeholder="Enter Password"
                                            className={errors.password ? " is-invalid " : ""}
                                            {...register("password", {
                                                required: "Please enter password",
                                                minLength: {
                                                    value: 8,
                                                    message:
                                                        "Password should contain atleast 8 characters",
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message:
                                                        "Password should contain maximum 16 characters",
                                                },

                                            })}
                                        />


                                        <div className="input-group-btn" style={{ position: 'absolute', right: '0', top: '-10', bottom: '0px', filter: `invert(1)` }}>
                                            <span className="btn btn-outline-primary" onClick={togglePassword} style={{ backgroundColor: 'transparent', border: '0', padding: '8px', paddingTop: '3px', height: '72%' }}>
                                                {passwordType === "password" ? <AiFillEyeInvisible /> : <AiFillEye />}
                                            </span>
                                        </div>
                                    </div>

                                </Form.Group>
                                {errors.password && errors.password.message && (
                                    <div className="text-danger">
                                        {errors.password.message}
                                    </div>
                                )}
                                <Form.Group className="login-data-d">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <div style={{ position: 'relative', width: `100%` }}>
                                        <Form.Control
                                            type={passwordType ? passwordType : "password"}
                                            placeholder="Confirm Password"
                                            className={errors.confirmPassword ? " is-invalid " : ""}
                                            {...register("confirmPassword", {
                                                required: "Please enter confirm password",
                                            })}
                                        />


                                        <div className="input-group-btn" style={{ position: 'absolute', right: '0', top: '-10', bottom: '0px', filter: `invert(1)` }}>
                                            <span className="btn btn-outline-primary" onClick={togglePassword} style={{ backgroundColor: 'transparent', border: '0', padding: '8px', paddingTop: '3px', height: '72%' }}>
                                                {passwordType === "password" ? <AiFillEyeInvisible /> : <AiFillEye />}
                                            </span>
                                        </div>
                                    </div>


                                </Form.Group>
                                {errors.confirmPassword &&
                                    errors.confirmPassword.message && (
                                        <div className="text-danger">
                                            {errors.confirmPassword.message}
                                        </div>
                                    )}
                                {password_same === false &&
                                    errors.confirmPassword !== "" && (
                                        <div className="text-danger">
                                            Password does not match.
                                        </div>
                                    )}
    
                            </div>



                            <button type="submit" className="submit-btn">
                                <span>{t("Confirm")}</span>
                            </button>

                        </Form>
        </div>
      </div>
      </div>
      
      </LayoutNew>
      

    
    </>
  );
};

export default ForgotPassword;
