import hotIcon from "../assets/images/tab-icon/hot.svg";
import cricketIcon from "../assets/images/tab-icon/cricket.svg";
import casinoIcon from "../assets/images/tab-icon/casino.svg";
import slotIcon from "../assets/images/tab-icon/slot.svg";
import tableIcon from "../assets/images/tab-icon/table.svg";
import sportsIcon from "../assets/images/tab-icon/sports.svg";
import fishingIcon from "../assets/images/tab-icon/fishing.svg";
import crashIcon from "../assets/images/tab-icon/crash.svg";
import lottertIcon from "../assets/images/tab-icon/lottery.png";



import loginBanner from "../assets/images/banner-image-1.jpg";
import loginBanner2 from "../assets/images/banner-image-2.jpg";
import iconExchange from "../assets/images/icon-exchange.svg";
import iconHorsebook from "../assets/images/icon-horsebook.svg";
import velkieLogo from "../assets/images/logo-velki.png";

import pakistanFlag from "../assets/images/flag-symbolism-Pakistan-design-Islamic.png";
import camodiaFlag from "../assets/images/Flag-Cambodia.png";
import philippinesFlag from "../assets/images/Flag_of_philippines.svg.jpg";
import brazilFlag from "../assets/images/Flag_of_Brazil.svg.png";
import indiaFlag from "../assets/images/Flag_of_India.svg.png";
import bangladeshFlag from "../assets/images/Flag_of_Bangladesh.svg.png";

// hotgame Image
import hot1 from "../assets/images/casino/hot-casino/AviatorClub.webp";
import hot2 from "../assets/images/casino/hot-casino/MX-LIVE-001.webp";
import hot3 from "../assets/images/casino/hot-casino/JILI-SLOT-051.webp";
import hot4 from "../assets/images/casino/hot-casino/JILI-SLOT-086.webp";
import hot5 from "../assets/images/casino/hot-casino/JILI-SLOT-088.webp";
import hot6 from "../assets/images/casino/hot-casino/JILI-SLOT-056.webp";
import hot7 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-006.webp";
import hot8 from "../assets/images/casino/hot-casino/JILI-SLOT-031.webp";
import hot9 from "../assets/images/casino/hot-casino/JILI-SLOT-079.webp";
import hot10 from "../assets/images/casino/hot-casino/JILI-FISH-007.webp";
import hot11 from "../assets/images/casino/hot-casino/JILI-SLOT-029.webp";
import hot12 from "../assets/images/casino/hot-casino/JILI-FISH-002.webp";
import hot13 from "../assets/images/casino/hot-casino/KM-TABLE-027.webp";
import hot14 from "../assets/images/casino/hot-casino/LUDO.webp";
import hot15 from "../assets/images/casino/hot-casino/KM-TABLE-033.webp";
import hot16 from "../assets/images/casino/hot-casino/KM-TABLE-036.webp";
import hot17 from "../assets/images/casino/hot-casino/KM-TABLE-022.webp";
import hot18 from "../assets/images/casino/hot-casino/KM-TABLE-021.webp";
import hot19 from "../assets/images/casino/hot-casino/KM-TABLE-038.webp";
import hot20 from "../assets/images/casino/hot-casino/KM-TABLE-042.webp";
import hot21 from "../assets/images/casino/hot-casino/KM-TABLE-041.webp";
import hot22 from "../assets/images/casino/hot-casino/SG-SLOT-106.webp";
import hot23 from "../assets/images/casino/hot-casino/PT-SLOT-082.webp";
import hot24 from "../assets/images/casino/hot-casino/PP-LIVE-044.webp";
import hot25 from "../assets/images/casino/hot-casino/JILI-FISH-005.webp";
import hot26 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-261.webp";
import hot27 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-183.webp";
import hot28 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-291.webp";
import hot29 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-007.webp";
import hot30 from "../assets/images/casino/hot-casino/JILI-SLOT-032.webp";
import hot31 from "../assets/images/casino/hot-casino/PP-LIVE-055.webp";
import hot32 from "../assets/images/casino/hot-casino/BG-LIVE-004.webp";
import hot33 from "../assets/images/casino/hot-casino/RT-SLOT-103.webp";
import hot34 from "../assets/images/casino/hot-casino/KM-TABLE-028.webp";
import hot35 from "../assets/images/casino/hot-casino/SG-SLOT-112.webp";
import hot36 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-014.webp";
import hot37 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-040.webp";
import hot38 from "../assets/images/casino/hot-casino/EVOLUTION-LIVE-230.webp";
import hot39 from "../assets/images/casino/hot-casino/JILI.webp";
import hot40 from "../assets/images/casino/hot-casino/JILI-TABLE-007.webp";
import hot41 from "../assets/images/casino/hot-casino/JILI-TABLE-017.webp";
import hot42 from "../assets/images/casino/hot-casino/PP-LIVE-038.webp";
import hot43 from "../assets/images/casino/hot-casino/KM-TABLE-043.webp";
import hot44 from "../assets/images/casino/hot-casino/PP-LIVE-042.webp";
import hot45 from "../assets/images/casino/hot-casino/aviatrix.webp";

// Cricket Image
import cricket1 from "../assets/images/casino/cricket/cricket1.webp";
import cricket2 from "../assets/images/casino/cricket/cricket2.webp";

// Casino Image
import casino1 from "../assets/images/casino/casino/casino1.webp";
import casino2 from "../assets/images/casino/casino/casino2.webp";
import casino3 from "../assets/images/casino/casino/casino3.webp";
import casino4 from "../assets/images/casino/casino/casino4.webp";
import casino5 from "../assets/images/casino/casino/casino5.webp";
import casino6 from "../assets/images/casino/casino/casino6.webp";
import casino7 from "../assets/images/casino/casino/casino7.webp";
import casino8 from "../assets/images/casino/casino/casino8.webp";
import casino9 from "../assets/images/casino/casino/casino9.webp";
import casino10 from "../assets/images/casino/casino/casino10.webp";
import casino11 from "../assets/images/casino/casino/casino11.webp";
// Slot Image
import slotJili from "../assets/images/casino/slot/jili.webp";
import slotJdb from "../assets/images/casino/slot/jdb.webp";
import slotdragong from "../assets/images/casino/slot/DRAGOONSOFT.webp";
import slotPt from "../assets/images/casino/slot/pt.webp";
import slotPp from "../assets/images/casino/slot/pp.webp";
import slotRt from "../assets/images/casino/slot/rt.webp";
import slotNlc from "../assets/images/casino/slot/NLC.webp";
import slotSg from "../assets/images/casino/slot/sg.webp";
import slotNetent from "../assets/images/casino/slot/NETENT.webp";
import SlotPg from "../assets/images/casino/slot/pg.webp";
import SlotYl from "../assets/images/casino/slot/YESBINGO.webp";

import SlotFc from "../assets/images/casino/slot/fc.webp";
import SlotKm from "../assets/images/casino/slot/km.png";
// Slot New Image
import newslotJili from "../assets/images/casino/slot/new/jili.webp";
import newslotJdb from "../assets/images/casino/slot/new/jdb.webp";
import newslotPt from "../assets/images/casino/slot/new/playtech.webp";
import newslotPp from "../assets/images/casino/slot/new/pp.webp";
import newslotRt from "../assets/images/casino/slot/new/rt.webp";
import newslotSg from "../assets/images/casino/slot/new/sg.webp";
import newSlotPg from "../assets/images/casino/slot/new/pg.webp";
import newSlotYl from "../assets/images/casino/slot/new/yl.webp";
import newSlotYesbingo from "../assets/images/casino/slot/new/yb.webp";
import newSlotFc from "../assets/images/casino/slot/new/fc.webp";

// Table Image
import tableJili from "../assets/images/casino/table/table_jili.webp";
import tableSpg from "../assets/images/casino/table/table_spg.webp";
import tableKm from "../assets/images/casino/table/table_km.webp";
import tableLudo from "../assets/images/casino/table/table_ludo.webp";
import tablePg from "../assets/images/casino/table/table_pg.webp";
import tableNetend from "../assets/images/casino/table/netend.webp";

// Sports Image
import sabaSports from "../assets/images/casino/sports/saba_sports.webp";

// Fishing Image
import fishingJili from "../assets/images/casino/fishing/fishing_jili.webp";
import fishingSpg from "../assets/images/casino/fishing/fishing_spg.webp";

import fishingFc from "../assets/images/casino/fishing/fishing_fc.webp";
import fishingJdb from "../assets/images/casino/fishing/fishing_jbd.webp";
import fishingYb from "../assets/images/casino/fishing/fishing_yb.webp";
import fishingYl from "../assets/images/casino/fishing/fishing_yl.webp";
// Crash Image

import exchangeIcon from "../assets/images/tab-icon/mobileBottomNavigation/exchange.svg";
import LiveCasinoIcon from "../assets/images/tab-icon/mobileBottomNavigation/livecasino.svg";
import HomeIcon from "../assets/images/tab-icon/mobileBottomNavigation/home.svg";
import SportbookIcon from "../assets/images/tab-icon/mobileBottomNavigation/sportbook.svg";
import AccountIcon from "../assets/images/tab-icon/mobileBottomNavigation/account.svg";


// Jili Slot Image
import superace from "../assets/images/casino/slot/jili/superace.webp";
import moneycoming from "../assets/images/casino/slot/jili/moneycoming.webp";
import goldenland from "../assets/images/casino/slot/jili/goldenland.webp";
import mayanempire from "../assets/images/casino/slot/jili/mayanempire.webp";
import alibaba from "../assets/images/casino/slot/jili/alibaba.webp";
import twinwins from "../assets/images/casino/slot/jili/twinwins.webp";
import agentace from "../assets/images/casino/slot/jili/agentace.jpg";
import boxingking from "../assets/images/casino/slot/jili/boxingking.jpg";
import crazyhunter from "../assets/images/casino/slot/jili/crazyhunter.jpg";
import fortunegems from "../assets/images/casino/slot/jili/fortunegems.jpg";
import goldenempire from "../assets/images/casino/slot/jili/goldenempire.jpg";
import jungleking from "../assets/images/casino/slot/jili/jungleking.jpg";
import luckycoming from "../assets/images/casino/slot/jili/luckycoming.jpg";
import magiclamp from "../assets/images/casino/slot/jili/magiclamp.jpg";
import partynight from "../assets/images/casino/slot/jili/partynight.jpg";
import pharaohtreasure from "../assets/images/casino/slot/jili/pharaohtreasure.jpg";
import romex from "../assets/images/casino/slot/jili/romex.jpg";
import streettreasure from "../assets/images/casino/slot/jili/streettreasure.jpg";
import superrion from "../assets/images/casino/slot/jili/superrion.jpg";
// Jili Table Image
import table7up7down from "../assets/images/casino/table/jili/7up7down.jpg";
import tableak47 from "../assets/images/casino/table/jili/ak47.jpg";
import tableandarbhar from "../assets/images/casino/table/jili/andarbhar.jpg";
import tablecallbreak from "../assets/images/casino/table/jili/callbreak.jpg";
import tabledragontiger from "../assets/images/casino/table/jili/dragontiger.jpg";
import tablejoker from "../assets/images/casino/table/jili/joker.jpg";
import tablejourneywest from "../assets/images/casino/table/jili/journeywest.jpg";
import tablenumberking from "../assets/images/casino/table/jili/numberking.jpg";
import tablepokerking from "../assets/images/casino/table/jili/pokerking.jpg";
import tablerichbingo from "../assets/images/casino/table/jili/richbingo.jpg";
import tablerummy from "../assets/images/casino/table/jili/rummy.jpg";
import tablesicbo from "../assets/images/casino/table/jili/sicbo.jpg";
import tabledice from "../assets/images/casino/table/jili/dice.jpg";

// Kingmaker Table Image
import km5cardpoker from "../assets/images/casino/table/kingmaker/5cardpoker.webp";
import km7up7down from "../assets/images/casino/table/kingmaker/7up7down.webp";
import km32cards from "../assets/images/casino/table/kingmaker/32cards.webp";
import kmandarbahar from "../assets/images/casino/table/kingmaker/andarbahar.webp";
import kmanimalrace from "../assets/images/casino/table/kingmaker/animalrace.webp";
import kmbaccarat from "../assets/images/casino/table/kingmaker/baccarat.webp";
import kmbingoroll from "../assets/images/casino/table/kingmaker/bingoroll.webp";
import kmblackjack from "../assets/images/casino/table/kingmaker/blackjack.webp";
import kmbolagolek from "../assets/images/casino/table/kingmaker/bolagolek.webp";
import kmbonusdice from "../assets/images/casino/table/kingmaker/bonusdice.webp";
import kmcardhilo from "../assets/images/casino/table/kingmaker/cardhilo.webp";
import kmcardmatka from "../assets/images/casino/table/kingmaker/cardmatka.webp";
import kmcashrocket from "../assets/images/casino/table/kingmaker/cashrocket.webp";
import kmcointoss from "../assets/images/casino/table/kingmaker/cointoss.webp";
import kmcolorgame from "../assets/images/casino/table/kingmaker/colorgame.webp";
import kmdota from "../assets/images/casino/table/kingmaker/dota.webp";
import kmhorseracing from "../assets/images/casino/table/kingmaker/horseracing.webp";
import kmhoundracing from "../assets/images/casino/table/kingmaker/houndracing.webp";
import kmjhandimunda from "../assets/images/casino/table/kingmaker/jhandimunda.webp";
import kmludo from "../assets/images/casino/table/kingmaker/ludo.webp";
import kmmarbleknockout from "../assets/images/casino/table/kingmaker/marbleknockout.webp";
import kmminesweeper from "../assets/images/casino/table/kingmaker/minesweeper.webp";
import kmmonkeyking from "../assets/images/casino/table/kingmaker/monkeyking.webp";
import kmnumbermatka from "../assets/images/casino/table/kingmaker/numbermatka.webp";
import kmplinko from "../assets/images/casino/table/kingmaker/plinko.webp";
import kmsicbo from "../assets/images/casino/table/kingmaker/sicbo.webp";
import kmtaixiu from "../assets/images/casino/table/kingmaker/taixiu.webp";
import kmtangkas from "../assets/images/casino/table/kingmaker/tangkas.webp";
import kmteenpatti from "../assets/images/casino/table/kingmaker/teenpatti.webp";
import kmthaihilo from "../assets/images/casino/table/kingmaker/thaihilo.webp";
import kmtreadmillracing from "../assets/images/casino/table/kingmaker/treadmillracing.webp";

// spade Table Image
import sgroulette from "../assets/images/casino/table/spade/roulette.webp";
import sgbelangakai from "../assets/images/casino/table/spade/belangakai.webp";
import sgdragontiger from "../assets/images/casino/table/spade/dragontiger.webp";
import sgfishprawn from "../assets/images/casino/table/spade/fishprawn.webp";
import sgpokdeng from "../assets/images/casino/table/spade/pokdeng.webp";
import sgsedie from "../assets/images/casino/table/spade/sedie.webp";
import sgsicbo from "../assets/images/casino/table/spade/sicbo.webp";
import sgthaifish from "../assets/images/casino/table/spade/thaifish.webp";

// Jili Fishing Image
import fhallstarfishing from "../assets/images/casino/fishing/jili/allstarfishing.webp";
import fhbombayfishing from "../assets/images/casino/fishing/jili/bombayfishing.webp";
import fhDinosaurTycoon from "../assets/images/casino/fishing/jili/DinosaurTycoon.webp";
import fhhappyfishing from "../assets/images/casino/fishing/jili/happyfishing.webp";
import fhjackpotfishing from "../assets/images/casino/fishing/jili/jackpotfishing.webp";
import fhmegafishing from "../assets/images/casino/fishing/jili/megafishing.webp";
import fhoceanking from "../assets/images/casino/fishing/jili/oceanking.webp";

// SPADE Fishing Image
import sgalienhunter from "../assets/images/casino/fishing/spade/alienhunter.webp";
import sgfishinggold from "../assets/images/casino/fishing/spade/fishinggold.webp";
import sgzombieparty from "../assets/images/casino/fishing/spade/zombieparty.webp";

// Crash Slot Image

import crashhotgame028 from "../assets/images/casino/crash/KM-TABLE-028.webp";
import crashhotgame035 from "../assets/images/casino/crash/JILI-TABLE-035.webp";
import crashhotgame030 from "../assets/images/casino/crash/JILI-TABLE-030.webp";
import crashhotgame060 from "../assets/images/casino/crash/KM-TABLE-060.webp";
import crashhotgame047 from "../assets/images/casino/crash/JILI-TABLE-047.webp";
import crashhotgame015 from "../assets/images/casino/crash/KM-TABLE-015.webp";
import crashhotgame021 from "../assets/images/casino/crash/KM-TABLE-021.webp";
import crashhotgame022 from "../assets/images/casino/crash/KM-TABLE-022.webp";
import crashpgdragon from "../assets/images/casino/crash/pgdragon.webp";


//LOtetry image
import lotetryEsports from "../assets/images/casino/lottery/ESPORTS.webp";
import lotteryKingmaker from "../assets/images/casino/lottery/KINGMAKER.webp";
import lotteryJilly from "../assets/images/casino/lottery/jili.webp";
import lotetryYesbingo from "../assets/images/casino/lottery/YESBINGO.webp";

import sabaSportsIcon from "../assets/images/casino/sports/SABA.webp";
import SABAVIRTUAL001 from "../assets/images/casino/sports/SABA-VIRTUAL-001.webp";


const obj = {
  user_status: {
    owner: "OW",
    sub_owner: "SOW",
    super_admin: "SUA",
    admin: "AD",
    sub_admin: "SAD",
    senior_super: "SSM",
    super_agent: "SA",
    agent: "AG",
    user: "CL",
  },
  user_next_status: {
    owner: "sub_owner",
    sub_owner: "super_admin",
    super_admin: "admin",
    admin: "sub_admin",
    sub_admin: "senior_super",
    senior_super: "super_agent",
    super_agent: "agent",
    agent: "user",
  },
  market_category: {
    1: "Market",
    3: "Bookmakers",
    5: "ManualOdds",
    10: "Win Toss",
    2: "Session",
    6: "Over by Over Session Market",
    7: "Player",
    9: "Wicket",
    11: "Bowler Session",
    8: "Last Digit ODD",
    4: "LineMarket",
    14: "Premium ODDs",
  },
  betCheckObj: {
    4: "Cricket",
    2: "Tennis",
    1: "Soccer",
    3: "Casino",
  },
  matchType: {
    cricket: "Cricket",
    tennis: "Tennis",
    soccer: "Soccer",
    casino: "Casino",
  },
};
export default obj;
export const priceFormat = (value) => {
  return new Intl.NumberFormat().format(value);
};
export const LoginBanner = [
  { id: 1, banner: loginBanner },
  { id: 2, banner: loginBanner2 },
];
export const CountryFlagBanner = [
  { id: 1, banner: pakistanFlag, title: "Pakistan" },
  { id: 2, banner: camodiaFlag, title: "camodia" },
  { id: 3, banner: philippinesFlag, title: "philippines" },
  { id: 4, banner: brazilFlag, title: "brazil" },
  { id: 5, banner: indiaFlag, title: "india" },
  { id: 6, banner: bangladeshFlag, title: "bangladesh" },
];
export const DepositWithrawDataNew = [
  {
    id: 1,
    icons: exchangeIcon,
    title: "Exchange",
    link: `${process.env.REACT_APP_SKY_URL}bkash/${localStorage.getItem(
      "token"
    )}`,
    titlebn: "সুপারিশ",
  },
  {
    id: 2,
    icons: LiveCasinoIcon,
    title: "Live Casino",
    link: "/all-casino/casino",
    titlebn: "প্রমোশন",
  },
  {
    id: 3,
    icons: HomeIcon,
    title: "Home",
    link: "/",
    titlebn: "বাড়ি",
  },
  {
    id: 4,
    icons: SportbookIcon,
    title: "Sportsbook",
    link: "/all-casino/sports",
    titlebn: "আমানত",
  },
  {
    id: 5,
    icons: AccountIcon,
    title: "Account",
    link: "/myaccount",
    titlebn: "হিসাব",
  },
];
export const SidebarMenu = [
  
  {
    id: 1,
    icons: cricketIcon,
    title: "Exchange",
    type: "cricket",
    titlebn: "ক্রিকেট",
  },
  {
    id: 2,
    icons: casinoIcon,
    title: "Live Casino",
    titlebn: "ক্যাসিনো",
    type: "casino",
  },
  { id: 3, icons: slotIcon, title: "Slot Games", titlebn: "স্লট", type: "slot" },
  {
    id: 4,
    icons: tableIcon,
    title: "Table Games",
    titlebn: "টেবিল খেলা",
    type: "table",
  },
  {
    id: 5,
    icons: sportsIcon,
    title: "Sportbook",
    type: "sports",
    titlebn: "এসবি",
  },
 

  {
    id:6,
    icons: fishingIcon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
  },
  {
    id: 7,
    icons: crashIcon,
    title: "Crash",
    type: "crash",
    titlebn: "ক্র্যাশ",
  },
  {
    id:8,
    icons: lottertIcon,
    title: "Lottery",
    type: "lottery",
    titlebn: "ক্র্যাশ",
  },
];
export const DepositWithrawData = [
  {
    id: 1,
    icons: hotIcon,
    title: "Hot Games",
    type: "hotgame",
    titlebn: "হট গেমস",
  },
  {
    id: 2,
    icons: cricketIcon,
    title: "Sports",
    type: "cricket",
    titlebn: "ক্রিকেট",
  },
  {
    id: 6,
    icons: sportsIcon,
    title: "Sportbook",
    type: "sports",
    titlebn: "এসবি",
  },
  {
    id: 3,
    icons: casinoIcon,
    title: "Casino",
    titlebn: "ক্যাসিনো",
    type: "casino",
  },
  { id: 4, icons: slotIcon, title: "Slot", titlebn: "স্লট", type: "slot" },
  {
    id: 5,
    icons: tableIcon,
    title: "Table",
    titlebn: "টেবিল খেলা",
    type: "table",
  },

  {
    id: 7,
    icons: fishingIcon,
    title: "Fishing",
    titlebn: "মাছ ধরা",
    type: "fish",
  },
  {
    id: 8,
    icons: crashIcon,
    title: "Crash",
    type: "crash",
    titlebn: "ক্র্যাশ",
  },
  {
    id: 8,
    icons: lottertIcon,
    title: "Lottery",
    type: "lottery",
    titlebn: "ক্র্যাশ",
  },
];
export const DepositWithrawDataArray = [
  {
    id: 1,
    title: "Hot",
    key: "hotgame",
    titlebn: "গরম খেল",

    array: [
      {
        id: 1,
        image: hot1,
        title: "AviatorClub",
        type: "AviatorClub",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "",
        casinoType: "",
      },
      {
        id: 2,
        image: hot2,
        title: "Baccara Classic",
        type: "Baccara Classic",
        gameTypeCheck: "platForm",
        platForm: "SEXYBCRT",
        gameType: "LIVE",
        casinoType: "MX-LIVE-001",
        table: 1,
      },
      {
        id: 3,
        image: hot3,
        title: "Mega Ace",
        type: "Mega Ace",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-051",
      },
      {
        id: 4,
        image: hot4,
        title: "Bangla Beauty",
        type: "Bangla Beauty",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-086",
      },
      {
        id: 5,
        image: hot5,
        title: "Dabang",
        type: "Dabang",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-088",
      },
      {
        id: 6,
        image: hot6,
        title: "Happy Taxi",
        type: "happytaxi",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-056",
      },
      {
        id: 7,
        image: hot7,
        title: "Crazy Time",
        type: "CrazyTime",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
      },
      {
        id: 8,
        image: hot8,
        title: "Boxing King",
        type: "Boxing King",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-031",
      },
      {
        id: 9,
        image: hot9,
        title: "Sweet Land",
        type: "sweetland",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-079",
      },
      {
        id: 10,
        image: hot10,
        title: "Mega Fishing",
        type: "Mega Fishing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "FH",
        casinoType: "JILI-FISH-007",
      },
      {
        id: 11,
        image: hot11,
        title: "Money Coming",
        type: "MOney COming",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-029",
      },
      {
        id: 12,
        image: hot12,
        title: "Bombing Fishing",
        type: "Bombing Fishing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "FH",
        casinoType: "JILI-FISH-002",
      },
      {
        id: 13,
        image: hot13,
        title: "european roulette",
        type: "european roulette",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-027",
      },
      {
        id: 14,
        image: hot14,
        title: "LUDO",
        type: "Ludo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        id: 15,
        image: hot15,
        title: "Pai Kang",
        type: "Pai Kang",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-033",
      },

      {
        id: 16,
        image: hot16,
        title: "Coin Toss",
        type: "cointoss",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-036",
      },
      {
        id: 17,
        image: hot17,
        title: "Card Matka",
        type: "cardmatka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-022",
      },
      {
        id: 18,
        image: hot18,
        title: "Number Matka",
        type: "numbermatka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-021",
      },
      {
        id: 19,
        image: hot19,
        title: "Blackjack",
        type: "Blackjack",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-038",
      },
      {
        id: 20,
        image: hot20,
        title: "minesweaper",
        type: "minesweaper",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-042",
      },
      {
        id: 21,
        image: hot21,
        title: "cash rocket",
        type: "cashrocket",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-041",
      },
      {
        id: 22,
        image: hot22,
        title: "Fiery Sevens Exclusive",
        type: "Fiery Sevens Exclusive",
        gameTypeCheck: "platForm",
        platForm: "SPADE",
        gameType: "SLOT",
        casinoType: "SG-SLOT-106",
      },
      {
        id: 23,
        image: hot23,
        title: "Football Rules",
        type: "Football Rules",
        gameTypeCheck: "platForm",
        platForm: "PT",
        gameType: "SLOT",
        casinoType: "PT-SLOT-082",
      },
      {
        id: 24,
        image: hot24,
        title: "Sweet Bonanza",
        type: "Sweet Bonanza",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-044",
      },

      {
        id: 25,
        image: hot25,
        title: "Happy Fishing",
        type: "Sweet Bonanza",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "FH",
        casinoType: "JILI-FISH-005",
      },
      {
        id: 26,
        image: hot26,
        title: "Crazy Pachinko",
        type: "Crazy Pachinko",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-261",
      },
      {
        id: 27,
        image: hot27,
        title: "FUnky Time",
        type: "Funky time",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-183",
      },
      {
        id: 28,
        image: hot28,
        title: "Stock Market",
        type: "Stock Market",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-291",
      },

      {
        id: 29,
        image: hot29,
        title: "Monopoly",
        type: "Monopoly",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-007",
      },

      {
        id: 30,
        image: hot30,
        title: "Matka India",
        type: "matkaindia",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-032",
      },
      {
        id: 31,
        image: hot31,
        title: "PowerUp Roulette",
        type: "PowerUp Roulette",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-055",
      },

      {
        id: 32,
        image: hot32,
        title: "Sicbo",
        type: "Sicbo",
        gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
        casinoType: "BG-LIVE-004",
      },
      {
        id: 33,
        image: hot33,
        title: "777 Strike",
        type: "777 Strike",
        gameTypeCheck: "platForm",
        platForm: "RT",
        gameType: "SLOT",
        casinoType: "RT-SLOT-103",
      },
      {
        id: 34,
        image: hot34,
        title: "7up7down",
        type: "7up7down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },

      {
        id: 35,
        image: hot35,
        title: "Friut Mango",
        type: "fruitmango",
        gameTypeCheck: "platForm",
        platForm: "SPADE",
        gameType: "SLOT",
        casinoType: "SG-SLOT-112",
      },
      {
        id: 36,
        image: hot36,
        title: "Crazy Coin FLip",
        type: "crazycoinflip",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-014",
      },
      {
        id: 37,
        image: hot37,
        title: "Lightning Roulette",
        type: "Lightning Roulette",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-040",
      },
      {
        id: 38,
        image: hot38,
        title: "Bac Bo",
        type: "BacBo",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-230",
      },
      {
        id: 39,
        image: hot39,
        title: "JILI",
        type: "jili",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "",
      },
      {
        id: 40,
        image: hot40,
        title: "Big Small",
        type: "bugsmall",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-007",
      },

      {
        id: 41,
        image: hot41,
        title: "Sicbo",
        type: "sicbo",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-017",
      },
      {
        id: 42,
        image: hot42,
        title: "mega wheel",
        type: "mega wheel",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-038",
      },
      {
        id: 43,
        image: hot43,
        title: "Bonus dice",
        type: "bonus dice",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-043",
      },
      {
        id: 44,
        image: hot44,
        title: "Dragon Tiger",
        type: "dragontiger",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-042",
      },
      {
        id: 45,
        image: hot45,
        title: "Aviatrix",
        type: "Aviatrix",
        gameTypeCheck: "platForm",
        platForm: "SPRIBE",
        gameType: "EGAME",
        casinoType: "SPRIBE-EGAME-001",
      },
    ],
  },
  {
    id: 2,
    title: "Cricket",
    key: "cricket",
    titlebn: "গরম খেল",
    array: [
      {
        image: cricket1,
        title: "Exchange",
        key: "cricket",
        gameTypeCheck: "cricket",
        link: `${process.env.REACT_APP_SKY_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
      {
        image: cricket2,
        title: "Velki",
        key: "cricket",
        gameTypeCheck: "cricket",
        link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
    ],
  },
  {
    id: 3,
    title: "Casino",
    key: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: casino1,
        title: "Evo",
        type: "evo",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbytrue:true
      },
      {
        image: casino2,
        title: "PP",
        type: "PP",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "",
        casinoType: "",
      },
      {
        image: casino3,
        title: "BG",
        type: "bg",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
      },
      {
        image: casino4,
        title: "HORSEBOOK",
        type: "HORSEBOOK",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      {
        image: casino5,
        title: "SV388",
        type: "SV388",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "SV388",
        gameType: "LIVE",
        casinoType: "SV-LIVE-001",
      },
      {
        image: casino6,
        title: "HOTROAD",
        type: "HOTROAD",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "HOTROAD",
        gameType: "LIVE",
        casinoType: "",
        isLobbytrue:true
      },
      {
        image: casino7,
        title: "PT",
        type: "PT",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "PT",
        gameType: "",
        casinoType: "",
      },
      {
        image: casino8,
        title: "BTG",
        type: "BTG",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "BTG",
        gameType: "",
        casinoType: "",
      },
    ],
  },
  {
    id: 4,
    title: "Slot",
    key: "slot",
    titlebn: "স্লট",
    array: [
      {
        image: slotPp,
        title: "PP",
        type: "pp",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PP",
      },
      {
        image: slotJili,
        title: "Jili",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
      },
      {
        image: slotJdb,
        title: "JDB",
        type: "jdb",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "JDB",
      },
      {
        image: slotdragong,
        title: "DRAGOONSOFT",
        type: "DRAGOONSOFT",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "DRAGOONSOFT",
      },
      {
        image: slotPt,
        title: "PT",
        type: "pt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PT",
      },
      {
        image: SlotFc,
        title: "FC",
        type: "fc",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },
      {
        image: SlotYl,
        title: "YL",
        type: "yl",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "YL",
      },
      {
        image: slotSg,
        title: "SG",
        type: "spade",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "SPADE",
      },
      {
        image: slotNetent,
        title: "NETENT",
        type: "NETENT",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "NETENT",
      },
      {
        image: slotRt,
        title: "RT",
        type: "rt",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "FC",
      },
      {
        image: slotNlc,
        title: "NLC",
        type: "NLC",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "NLC",
      },

      {
        image: SlotPg,
        title: "PG",
        type: "pg",
        key: "slot",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "PG",
      },
    ],
  },
  {
    id: 5,
    title: "Table",
    key: "table",
    titlebn: "টেবিল",
    array: [
      {
        image: tableJili,
        title: "Jili",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "JILI",
      },
      {
        image: tableKm,
        title: "KM",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "KINGMAKER",
      },
      {
        image: tableSpg,
        title: "SG",
        type: "spade",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "SPADE",
      },
      {
        image: tableNetend,
        title: "NETENT",
        type: "NETENT",
        key: "table",
        gameTypeCheck: "platForm",
        gameType: "TABLE",
        platForm: "NETENT",
        casinoType: "NETENT-TABLE-001",
      },
      // {
      //   image: tablePg,
      //   title: "PG",
      //   type: "pg",
      //   key: "table",
      //   gameTypeCheck: "platForm",
      //   gameType: "TABLE",
      //   platForm: "PG",
      //   casinoType:"PG-TABLE-001"
      // },
    ],
  },
  {
    id: 6,
    title: "Sports",
    key: "sports",
    titlebn: "বিনিময়",
    array: [
      {
        image: sabaSports,
        title: "Saba Sports",
        link: "",
        key: "sports",
        gameTypeCheck: "platForm",
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },

      // {
      //   image: iconHorsebook,
      //   title: "Horsebook",
      //   link: "",
      //   gameTypeCheck: "platForm",
      //   platForm: "HORSEBOOK",
      //   gameType: "LIVE",
      //   casinoType: "HRB-LIVE-001",
      // },
    ],
  },

  {
    id: 7,
    title: "Fishing",
    key: "fish",
    type: "fish",
    array: [
      {
        image: fishingJili,
        title: "Jili",
        type: "jili",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JILI",
      },
      {
        image: fishingSpg,
        title: "Spade",
        type: "spade",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "SPADE",
      },
      {
        image: fishingFc,
        title: "FC Fishing",
        type: "fc",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "FC",
      },
      {
        image: fishingJdb,
        title: "JDB Fishing",
        type: "jdb",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "JDB",
      },
      {
        image: fishingYb,
        title: "Yes Bingo",
        type: "yesbingo",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YESBINGO",
        casinoType: "YesBingo-FH-001",
      },
      {
        image: fishingYl,
        title: "Yl Fishing",
        type: "yl",
        key: "fish",
        gameTypeCheck: "platForm",
        gameType: "FH",
        platForm: "YL",
      },
    ],
  },

  {
    id: 8,
    title: "Crash",
    key: "crash",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        id: 1,
        image: crashhotgame030,
        title: "Go Rush",
        type: "Go Rush",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-030",
      },
      {
        id: 2,
        image: crashhotgame047,
        title: "Crash Bonus",
        type: "Crash Bonus",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-047",
      },
      {
        id: 3,
        image: crashhotgame035,
        title: "Limbo",
        type: "Limbo",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-035",
      },
      {
        id: 4,
        image: crashhotgame021,
        title: "Sugar Blast",
        type: "Sugar Blast",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "SLOT",
        casinoType: "KM-SLOT-001",
      },
      {
        id: 5,
        image: crashhotgame015,
        title: "Sicbo",
        type: "Sicbo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-015",
      },

      {
        id: 6,
        image: crashhotgame021,
        title: "Matka",
        type: "Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-021",
      },
      {
        id: 7,
        image: crashhotgame022,
        title: "Card Matka",
        type: "Card Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-022",
      },
      {
        id: 8,
        image: crashhotgame028,
        title: "7 Up 7 Down",
        type: "7 Up 7 Down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },
      {
        id: 9,
        image: crashhotgame060,
        title: "Ludo",
        type: "Ludo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        id: 10,
        image: crashpgdragon,
        title: "Dragon ",
        type: "Dragon Tiger",
        gameTypeCheck: "platForm",
        platForm: "PG",
        gameType: "SLOT",
        casinoType: "PG-SLOT-035",
      },
    ],
  },
  {
    id: 8,
    title: "Lottery",
    key: "lottery",
    titlebn: "স্পোর্টসবুক",
    array: [
      {
        image: lotetryEsports,
        title: "ESPORTS",
        type: "ESPORTS",
        key: "lottery",
        gameTypeCheck: "platForm",
        platForm: "E1SPORT",
        gameType: "ESPORTS",
        casinoType:"E1-ESPORTS-001"
      },
      {
        image: lotteryKingmaker,
        title: "KINGMAKER",
        type: "kingmaker",
        key: "lottery",
        gameTypeCheck: "platForm",
        gameType: "SLOT",
        platForm: "KINGMAKER",
      },
      {
        image: lotteryJilly,
        title: "Jili",
        type: "jili",
        key: "lottery",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
      },
      
      {
        image: lotetryYesbingo,
        title: "YESBINGO",
        type: "YESBINGO",
        key: "Lotetry",
        gameTypeCheck: "platForm",
        gameType: "BINGO",
        platForm: "YESBINGO",
      },
     
    ],
  },
];
export const AllCasinoTabing = [
  {
    id: 1,
    icons: iconExchange,
    title: "Exchange",
    key: "exchange",
    type: "cricket",
    titlebn: "গরম খেল",
  },
  {
    id: 1,
    icons: velkieLogo,
    title: "Velki",
    key: "velki",
    type: "cricket",
    titlebn: "গরম খেল",
  },

  {
    id: 1,
    icons: slotJili,
    title: "Jili",
    key: "jili",
    type: "slot",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: slotJdb,
    title: "JDB",
    key: "jdb",
    type: "slot",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: slotPt,
    title: "PT",
    key: "pt",
    type: "slot",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: slotPp,
    title: "PP",
    key: "pp",
    type: "slot",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: slotRt,
    title: "RT",
    key: "rt",
    type: "slot",
    titlebn: "গরম খেল",
  },
  {
    id: 2,
    icons: slotSg,
    title: "SG",
    key: "sg",
    type: "slot",
    titlebn: "গরম খেল",
  },

  {
    id: 4,
    icons: slotJdb,
    title: "JDB",
    key: "jdb",
    type: "table",
    titlebn: "গরম খেল",
  },
  {
    id: 3,
    icons: slotJili,
    title: "Jili",
    key: "jili",
    type: "table",
    titlebn: "গরম খেল",
  },
  {
    id: 3,
    icons: slotNetent,
    title: "NETENT",
    key: "netent",
    type: "table",
    titlebn: "গরম খেল",
  },
  {
    id: 3,
    icons: SlotKm,
    title: "Kingmaker",
    key: "kingmaker",
    type: "table",
    titlebn: "গরম খেল",
  },
 
  


  {
    id: 4,
    icons: slotJili,
    title: "jili",
    key: "jili",
    type: "fish",
    titlebn: "গরম খেল",
  },
  {
    id: 4,
    icons: slotSg,
    title: "Spade Game",
    key: "spade",
    type: "fish",
    titlebn: "গরম খেল",
  },
  {
    id: 4,
    icons: sabaSportsIcon,
    title: "SABA",
    key: "sports",
    type: "sports",
    titlebn: "গরম খেল",
  },
];

export const AllCasinoTabingArray = [
  {
    id: 1,
    key: "exchange",
    type: "cricket",
    array: [
      {
        image: cricket1,
        title: "Exchange",
        key: "cricket",
        gameTypeCheck: "cricket",
        link: `${process.env.REACT_APP_SKY_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
    ],
  },
  {
    id: 1,
    key: "velki",
    type: "cricket",
    array: [
      {
        image: cricket2,
        title: "Velki",
        key: "cricket",
        gameTypeCheck: "cricket",
        link: `${process.env.REACT_APP_VELKIE_URL}bkash/${localStorage.getItem(
          "token"
        )}`,
      },
    ],
  },
  {
    id: 1,
    key: "jili",
    type: "slot",
    array: [
      {
        image: superace,
        title: "Super Ace",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-027",
      },
      {
        image: moneycoming,
        title: "Money Coming",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-029",
      },
      {
        image: goldenland,
        title: "Golden Land",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-027",
      },
      {
        image: mayanempire,
        title: "Mayan Empire",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "",
      },
      {
        image: alibaba,
        title: "Ali baba",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "",
      },
      {
        image: twinwins,
        title: "Twins Twins",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "",
      },
      {
        image: agentace,
        title: "Agent Ace",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-048",
      },
      {
        image: boxingking,
        title: "boxing king",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-031",
      },
      {
        image: crazyhunter,
        title: "crazy hunter",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-045",
      },
      {
        image: fortunegems,
        title: "fortune gems",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-043",
      },
      {
        image: goldenempire,
        title: "golden empire",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-042",
      },
      {
        image: jungleking,
        title: "jungle king",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-028",
      },
      {
        image: luckycoming,
        title: "lucky coming",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-037",
      },
      {
        image: magiclamp,
        title: "magic lamp",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-046",
      },
      {
        image: partynight,
        title: "party night",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-044",
      },

      {
        image: pharaohtreasure,
        title: "pharaoh treasure",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-038",
      },
      {
        image: romex,
        title: "romax",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-040",
      },
      {
        image: streettreasure,
        title: "secret treasure",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-039",
      },
      {
        image: superrion,
        title: "superrion",
        type: "jili",
        key: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-041",
      },
    ],
  },
  {
    id: 1,
    key: "jdb",
    type: "slot",
    array: [
      {
        image: slotJdb,
        title: "jdb",
        key: "jdb",
        type: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JDB",
      },
    ],
  },
  {
    id: 1,
    key: "pt",
    type: "slot",
    array: [
      {
        image: slotJili,
        title: "pt",
        key: "pt",
        type: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "PT",
      },
    ],
  },
  {
    id: 1,
    key: "pp",
    type: "slot",
    array: [
      {
        image: slotJili,
        title: "pp",
        key: "pp",
        type: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "PP",
      },
    ],
  },
  {
    id: 1,
    key: "rt",
    type: "slot",
    array: [
      {
        image: slotJili,
        title: "rt",
        key: "rt",
        type: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "RT",
      },
    ],
  },
  {
    id: 1,
    key: "sg",
    type: "slot",
    array: [
      {
        image: slotJili,
        title: "sg",
        key: "sg",
        type: "slot",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "SG",
      },
    ],
  },
  {
    id: 2,
    key: "jili",
    type: "table",
    array: [
      {
        image: table7up7down,
        title: "7up7down",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-011",
      },
      {
        image: tablesicbo,
        title: "Sic bo",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-017",
      },
      {
        image: tablejoker,
        title: "Teen Patti Joker",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-010",
      },
      {
        image: tablecallbreak,
        title: "Callbreak",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-013",
      },
      {
        image: tablerummy,
        title: "Rummy",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-004",
      },
      {
        image: tablerichbingo,
        title: "Irich bingo",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-008",
      },
      {
        image: tableandarbhar,
        title: "Andar Bahar",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-003",
      },
      {
        image: tabledragontiger,
        title: "Dragon & Tiger",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-012",
      },
      {
        image: tableak47,
        title: "Ak47",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-002",
      },
      {
        image: tablepokerking,
        title: "Poker King",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-006",
      },
      {
        image: tablejourneywest,
        title: "Journey West",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-029",
      },
      {
        image: tablenumberking,
        title: "Number King",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "JILI",
        casinoType: "JILI-TABLE-005",
      },
      {
        image: tabledice,
        title: "Dice",
        type: "jili",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "JILI",
        casinoType: "JILI-SLOT-033",
      },
    ],
  },
  {
    id: 2,
    key: "kingmaker",
    type: "table",
    array: [
      {
        image: km5cardpoker,
        title: "5 Card Poker",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-049",
      },
      {
        image: km7up7down,
        title: "7 Up 7 Down",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-028",
      },
      {
        image: km32cards,
        title: "32cards",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-039",
      },
      {
        image: kmandarbahar,
        title: "Andar Bahar",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-032",
      },
      {
        image: kmanimalrace,
        title: "animal race",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-062",
      },
      {
        image: kmbaccarat,
        title: "baccarat",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-026",
      },
      {
        image: kmbingoroll,
        title: "bingoroll",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-051",
      },
      {
        image: kmblackjack,
        title: "blackjack",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-038",
      },
      {
        image: kmbolagolek,
        title: "bola golek",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-035",
      },
      {
        image: kmbonusdice,
        title: "bonus dice",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-043",
      },
      {
        image: kmcardhilo,
        title: "cards hi lo",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-037",
      },
      {
        image: kmcardmatka,
        title: "card matka",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-022",
      },
      {
        image: kmcashrocket,
        title: "cash rocket",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-041",
      },
      {
        image: kmcointoss,
        title: "coin toss",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-036",
      },
      {
        image: kmcolorgame,
        title: "color game",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-050",
      },
      {
        image: kmdota,
        title: "dota hi lo",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-044",
      },
      {
        image: kmhorseracing,
        title: "horse racing",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-048",
      },
      {
        image: kmhoundracing,
        title: "hound racing",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-055",
      },
      {
        image: kmjhandimunda,
        title: "jhandi munda",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-030",
      },
      {
        image: kmludo,
        title: "ludo",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-060",
      },
      {
        image: kmmarbleknockout,
        title: "marble knockout",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-053",
      },
      {
        image: kmminesweeper,
        title: "mine sweeper",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-042",
      },
      {
        image: kmmonkeyking,
        title: "mine sweeper",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-045",
      },
      {
        image: kmnumbermatka,
        title: "Number Matka",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-021",
      },
      {
        image: kmplinko,
        title: "plinko",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-040",
      },
      {
        image: kmsicbo,
        title: "sicbo",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-015",
      },
      {
        image: kmtaixiu,
        title: "tai xiu",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-017",
      },
      {
        image: kmtangkas,
        title: "tangkas",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-052",
      },
      {
        image: kmteenpatti,
        title: "teenpatti",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-046",
      },
      {
        image: kmthaihilo,
        title: "thai hilo",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-009",
      },
      {
        image: kmtreadmillracing,
        title: "treadmill racing",
        type: "kingmaker",
        key: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "TABLE",
        platForm: "KINGMAKER",
        casinoType: "KM-TABLE-058",
      },
    ],
  },
  {
    id: 2,
    key: "spade",
    type: "table",
    array: [
      {
        image: sgbelangakai,
        title: "belangakai",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "SPADE",
        casinoType: "SG-SLOT-032",
      },
      {
        image: sgdragontiger,
        title: "dragontiger",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "SPADE",
        casinoType: "SSG-SLOT-039",
      },
      {
        image: sgfishprawn,
        title: "fishprawn",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FH",
        platForm: "SPADE",
        casinoType: "SG-FISH-001",
      },
      {
        image: sgpokdeng,
        title: "pokdeng",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "SPADE",
        casinoType: "SG-SLOT-095",
      },
      {
        image: sgroulette,
        title: "roulette",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "LIVE",
        platForm: "PT",
        casinoType: "PT-LIVE-021",
      },
      {
        image: sgsedie,
        title: "belangakai",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "SPADE",
        casinoType: "SG-SLOT-039",
      },
      {
        image: sgsicbo,
        title: "belangakai",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "LIVE",
        platForm: "PP",
        casinoType: "PP-LIVE-025",
      },
      {
        image: sgthaifish,
        title: "belangakai",
        key: "spade",
        type: "table",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "SLOT",
        platForm: "SPADE",
        casinoType: "SG-SLOT-039",
      },
    ],
  },
  {
    id: 2,
    key: "sports",
    type: "sports",
    array: [
      {
        image: SABAVIRTUAL001,
        title: " Virtual Sports ",
        key: "sports",
        type: "sports",
        gameTypeCheck: "platForm",
        isPlateform: true,
        platForm: "SABA",
        gameType: "VIRTUAL",
        casinoType: "SABA-VIRTUAL-001",
      },
    ],
  },
  {
    id: 2,
    key: "jili",
    type: "fish",
    array: [
      {
        image: fhallstarfishing,
        title: "All Star Fishing",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-009",
      },
      {
        image: fhbombayfishing,
        title: "bombay fishing",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-002",
      },
      {
        image: fhDinosaurTycoon,
        title: "Dinosaur Tycoon",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-004",
      },
      {
        image: fhhappyfishing,
        title: "happy fishing",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-005",
      },
      {
        image: fhjackpotfishing,
        title: "jackpot fishing",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-003",
      },
      {
        image: fhmegafishing,
        title: "Mega fishing",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-007",
      },
      {
        image: fhoceanking,
        title: "ocean king",
        key: "jili",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FISH",
        platForm: "JILI",
        casinoType: "JILI-FISH-012",
      },
    ],
  },
  {
    id: 2,
    key: "spade",
    type: "fish",
    array: [
      {
        image: sgalienhunter,
        title: "alien hunter",
        key: "spade",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FH",
        platForm: "SPADE",
        casinoType: "SG-FISH-003",
      },
      {
        image: sgfishinggold,
        title: "fishing gold",
        key: "spade",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FH",
        platForm: "SPADE",
        casinoType: "SG-FISH-001",
      },
      {
        image: sgzombieparty,
        title: "zombie party",
        key: "spade",
        type: "fish",
        gameTypeCheck: "platForm",
        isPlateform: true,
        gameType: "FH",
        platForm: "SPADE",
        casinoType: "SG-FISH-004",
      },
    ],
  },
  {
    id: 2,
    key: "crash",
    type: "crash",
    array: [
      {
        id: 1,
        image: crashhotgame030,
        title: "Go Rush",
        type: "Go Rush",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-030",
      },
      {
        id: 2,
        image: crashhotgame047,
        title: "Crash Bonus",
        type: "Crash Bonus",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-047",
      },
      {
        id: 3,
        image: crashhotgame035,
        title: "Limbo",
        type: "Limbo",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "TABLE",
        casinoType: "JILI-TABLE-035",
      },
      {
        id: 4,
        image: crashhotgame021,
        title: "Sugar Blast",
        type: "Sugar Blast",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "SLOT",
        casinoType: "KM-SLOT-001",
      },
      {
        id: 5,
        image: crashhotgame015,
        title: "Sicbo",
        type: "Sicbo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-015",
      },

      {
        id: 6,
        image: crashhotgame021,
        title: "Matka",
        type: "Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-021",
      },
      {
        id: 7,
        image: crashhotgame022,
        title: "Card Matka",
        type: "Card Matka",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-022",
      },
      {
        id: 8,
        image: crashhotgame028,
        title: "7 Up 7 Down",
        type: "7 Up 7 Down",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-028",
      },
      {
        id: 9,
        image: crashhotgame060,
        title: "Ludo",
        type: "Ludo",
        gameTypeCheck: "platForm",
        platForm: "KINGMAKER",
        gameType: "TABLE",
        casinoType: "KM-TABLE-060",
      },
      {
        id: 10,
        image: crashpgdragon,
        title: "Dragon ",
        type: "Dragon Tiger",
        gameTypeCheck: "platForm",
        platForm: "PG",
        gameType: "SLOT",
        casinoType: "PG-SLOT-035",
      },
    ],
  },
  {
    id: 3,
    title: "Casino",
    key: "casino",
    type: "casino",
    titlebn: "লাইভ ক্যাসিনো",
    array: [
      {
        image: casino1,
        title: "Evo",
        type: "evo",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
        isLobbytrue:true
      },
      {
        image: casino2,
        title: "PP",
        type: "PP",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "",
        casinoType: "",
      },
      {
        image: casino3,
        title: "BG",
        type: "bg",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "BG",
        gameType: "LIVE",
      },
      {
        image: casino4,
        title: "HORSEBOOK",
        type: "HORSEBOOK",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "HORSEBOOK",
        gameType: "LIVE",
        casinoType: "HRB-LIVE-001",
      },
      {
        image: casino5,
        title: "SV388",
        type: "SV388",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "SV388",
        gameType: "LIVE",
        casinoType: "SV-LIVE-001",
      },
      {
        image: casino6,
        title: "HOTROAD",
        type: "HOTROAD",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "HOTROAD",
        gameType: "LIVE",
        casinoType: "",
        isLobbytrue:true
      },
      {
        image: casino7,
        title: "PT",
        type: "PT",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "PT",
        gameType: "",
        casinoType: "",
      },
      {
        image: casino8,
        title: "BTG",
        type: "BTG",
        key: "casino",
        gameTypeCheck: "platForm",
        platForm: "BTG",
        gameType: "",
        casinoType: "",
      },
    ],
  },
  {
    id: 1,
    title: "Hot",
    key: "hotgame",
    type: "hotgame",
    titlebn: "গরম খেল",
    array: [
      {
        id: 1,
        image: hot1,
        title: "Sweet Land",
        type: "SweetLand",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-079",
      },
      {
        id: 2,
        image: hot2,
        title: "Fortune Gems",
        type: "FortuneGems",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-043",
      },
      {
        id: 3,
        image: hot3,
        title: "Boxing King",
        type: "BoxingKing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-031",
      },
      {
        id: 4,
        image: hot4,
        title: "Funky Time",
        type: "funkytime",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-183",
      },
      {
        id: 5,
        image: hot5,
        title: "Crazy Time",
        type: "crazytime",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-006",
      },
      {
        id: 6,
        image: hot6,
        title: "Lightning Roulette",
        type: "LightningRoulette",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-040",
      },
      {
        id: 7,
        image: hot7,
        title: "Monopoly",
        type: "monopoly",
        gameTypeCheck: "platForm",
        platForm: "EVOLUTION",
        gameType: "LIVE",
        casinoType: "EVOLUTION-LIVE-007",
      },
      {
        id: 8,
        image: hot9,
        title: "Bombing Fishing",
        type: "BombingFishing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "FISH",
        casinoType: "JILI-FISH-002",
      },
      {
        id: 9,
        image: hot10,
        title: "Mega Fishing",
        type: "MegaFishing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "FISH",
        casinoType: "JILI-FISH-007",
      },
      {
        id: 10,
        image: hot11,
        title: "Money Coming",
        type: "MoneyComing",
        gameTypeCheck: "platForm",
        platForm: "JILI",
        gameType: "SLOT",
        casinoType: "JILI-SLOT-029",
      },
      {
        id: 11,
        image: hot12,
        title: "Mega Roullete",
        type: "MegaRoullete",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-039",
      },
      {
        id: 12,
        image: hot13,
        title: "Mega Wheel",
        type: "MegaWheel",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-038",
      },
      {
        id: 13,
        image: hot14,
        title: "Mega Sicbo",
        type: "MegaSicbo",
        gameTypeCheck: "platForm",
        platForm: "PP",
        gameType: "LIVE",
        casinoType: "PP-LIVE-025",
      },
    ],
  },
];
export const validationRules = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
  newPass: /^[a-zA-Z0-9]{8,15}$/,
  newPassMessage:
    "Password must contain uppercase and lowercase characters, numbers and must be minimum 8 character long (without special character).",
  passwordMessage:
    "Password must contain uppercase and lowercase characters, numbers, special character and must be minimum 8 character long.",
  characters: /^[a-zA-Z_ ]*$/,
  numbers: /^[0-9]*$/,
  removeWhitespace: /^[a-zA-Z0-9]+$/,
  numberNew: /^[0-9]*$/,
  numberWithDot: /^\d*(\.\d{0,10})?$/,
};
export const preventMaxInput = (e) => {
  e.target.value = e.target.value.trimStart();
  e.target.value = e.target.value.replace(/  +/g, " ");
};
export const AmountArray = [
  { id: 1, amount: 100 },
  { id: 2, amount: 500 },
  { id: 3, amount: 1000 },
  { id: 4, amount: 5000 },
  { id: 5, amount: 1000 },
  { id: 6, amount: 15000 },
  { id: 7, amount: 20000 },
  { id: 8, amount: 25000 },
];
