import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  AllCasinoTabing,
  AllCasinoTabingArray,
} from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { isEmpty ,startCase} from "lodash";
import { useNavigate,useParams } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import LayoutNew from "../components/shared/LayoutNew";

const AllCasino = () => {


  const [data, setData] = useState({});
  const [tabingData, setTabingData] = useState({});
  const parmas = useParams();
  const navigate = useNavigate();
  const {
    lang,
    user,
    launchEGTCasino,
    launchCasino,
    logoutUser,
    withoutLogin,
  } = useContext(AuthContext);
  const [key, setKey] = useState("exchange");
console.log(parmas?.type);
  useEffect(() => {
  
    setTabingData(
      AllCasinoTabing?.filter((res) => {
        {
          return res?.type == parmas?.type;
        }
      })
    );
    setData(
      AllCasinoTabingArray?.find((res) => {
        {
          return res?.type == parmas?.type;
        }
      })
    );
  }, [parmas?.type]);

  const setFunc = (type ,key) => {
    
    setData(
      AllCasinoTabingArray?.find((res) => {
        {
          return res?.key == key && res?.type == type;
        }
      })
    );
    setKey(key);
  };
  
 
  console.log("data",data);
  return (
    <LayoutNew>
      <div class="game-page-card-menu hidden-md-and-up text-center v-card v-sheet theme--light">
            
              {startCase(parmas?.type)}
             
        </div>
    <div className="home-tabing-slider all-casino-data">
      <div className="payment-sec">
        {tabingData.length>0 && tabingData?.map((item) => {
          return (
            <div
              onClick={() => {setFunc(parmas?.type,item?.key);}}
              className={`payment-box all-casino-tab ${key == item?.key ? "active" : ""}`}
            >
              <figure>
                <img
                  src={item?.icons}
                  alt=""
                  className={item?.filter == "" && "sportsbook-filter"}
                />
              </figure>
              <span>{lang == "bn" ? item?.titlebn : item?.title}</span>
            </div>
          );
        })}
      </div>
      <div className="main-slider-div">
        <div className="main-slider-inner-div" style={{padding: `0 15px`}}>
          <div>
            <h5>{lang == "bn" ? data?.titlebn : data?.title}</h5>
          </div>
         
          <ul>
            {data?.array?.length && data?.array?.map((res) => {
              return (
                <li  style={{color:`red`,backgroundImage: `url(${res?.image})`}}
                className={res?.key=="cricket" || res?.key=="fish"?"gT_cricket":res?.key=="casino" ?"gT_ld":""}
                onClick={() => {
                  if (!isEmpty(user)) {
                    if (data?.key == "exchange") {
                      window.open(res?.link, "_self");
                    } else {
                      if (!isEmpty(user)) {
                        if (res?.gameTypeCheck == "platForm") {
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                          });
                        } else if (res?.gameType == "egtGame") {
                          launchEGTCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameTypeNew,
                            casinoType: res?.casinoType,
                          });
                        } else if (!isEmpty(res?.gameid)) {
                          launchEGTCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                            gameid: res?.gameid,
                          });
                        } else {
                          if (
                            res?.platForm !== "" &&
                            res?.gameType !== "" &&
                            res?.casinoType !== ""
                          ) {
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                            });
                          }
                        }
                      } else {
                        navigate("/login");
                      }
                    }
                  } else {
                    withoutLogin()
                  }
                }}>

                  <div
                   
                  >
                    {/* <span>
                      <LazyLoadImage
                        effect="opacity"
                        alt={res?.img}
                        src={res?.image}
                      />
                    </span>
                    <p>{res?.title}</p> */}
                  </div>
                </li>
              );
            })}
           
          </ul>
        </div>
      </div>
    </div>
    </LayoutNew>
  );
};

export default AllCasino;
