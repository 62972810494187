import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import {
  DepositWithrawData,
  DepositWithrawDataArray,
} from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";
import { icons } from "react-icons";
const GameSlider = () => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplay: false,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      },
    ],
  };
  const ref = useRef();
  const [data, setData] = useState({});

  const [activeClass, SetActiveClass] = useState("");
  const navigate = useNavigate();
  const {
    lang,
    user,
    launchEGTCasino,
    launchCasino,
    logoutUser,
    withoutLogin,
  } = useContext(AuthContext);
  const [key, setKey] = useState("hotgame");
  

  useEffect(() => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == "hotgame";
        }
      })
    );

  //   window.addEventListener('scroll', () => {
  //     if(window.scrollY > 180){
       
  //       SetActiveClass('gamefixed');
  //     }else{
  //       SetActiveClass('');
  //     }
      
  //  });

  }, []);

  const setFunc = (type) => {
    setData(
      DepositWithrawDataArray?.find((res) => {
        {
          return res?.key == type;
        }
      })
    );
    setKey(type);
  };
 
  return (
    <div className="home-tabing-slider">
      <div className="payment-sec"  {...settings}>
        {DepositWithrawData?.map((item) => {
          return (
            <div
              onClick={() => {
                // if (isEmpty(user)) {
                //   withoutLogin();
                // } else {
                  if (!isEmpty(item?.link)) {
                    navigate(item?.link);
                  } else {
                    setFunc(item?.type);
                  }
                // }
              }}
              className={`payment-box ${key == item?.type ? "active" : ""}`}
            >
              <figure>
                <img
                  src={item?.icons}
                  alt=""
               
                />
              </figure>
              <span>{lang == "bn" ? item?.titlebn : item?.title}</span>
            </div>
          );
        })}
      </div>
      <div className="main-slider-div">
        <div className="main-slider-inner-div">
          
          <ul>
            { data?.array?.map((res) => {
              return (
                <li  
                className={res?.key=="cricket" || res?.key=="sports"  ?"gT_cricket":res?.key=="casino" || res?.key=="table" || res?.key=="fish"?"gT_ld":res?.key=="slot"?"slot_cas":""}
                onClick={() => {
                  if (!isEmpty(user)) {
                   
                      if (!isEmpty(user)) {
                       if (res?.gameTypeCheck == "cricket") {
                        window.open(res?.link, "_self");
                       }else if (res?.isPlateform) {
                        navigate("/all-casino/"+res?.key)
                        } else if (res?.gameTypeCheck == "platForm") {
                          launchCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                            isLobbytrue:res?.isLobbytrue,
                          });
                        } else if (res?.gameType == "egtGame") {
                          launchEGTCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameTypeNew,
                            casinoType: res?.casinoType,
                          });
                        } else if (!isEmpty(res?.gameid)) {
                          launchEGTCasino({
                            platForm: res?.platForm,
                            gameType: res?.gameType,
                            casinoType: res?.casinoType,
                            gameid: res?.gameid,
                          });
                        } else {
                          if (
                            res?.platForm !== "" &&
                            res?.gameType !== "" &&
                            res?.casinoType !== ""
                          ) {
                            launchCasino({
                              platForm: res?.platForm,
                              gameType: res?.gameType,
                              casinoType: res?.casinoType,
                             isLobbytrue:res?.isLobbytrue,

                            });
                          }
                        }
                      } else {
                        navigate("/login");
                      }
                    
                  } else {
                    withoutLogin()
                  }
                }}>
                  <div style={{color:`red`,backgroundImage: `url(${res?.image})`}}
                >
                    
                      {/* <LazyLoadImage
                        effect="opacity"
                        alt={res?.img}
                        src={res?.image}
                      /> */}
                  
                   
                  </div>
                  { res?.key=="slot" && <p>{res?.title}</p> }
                </li>
              );
            })}
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default GameSlider;
